.header__wrapper {
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;


}

.header__left {
  display: flex;
  align-items: center;
  border-right: 1px solid black;
  padding: 6px;
  background-color: white;
  margin-left: 5px;
}

.header__right {
  display: flex;
  align-items: center;
  background-color: white;
  margin-right: 40px;
}

.header__button {
  display: flex;
  margin-right: 10px;
  margin-left: 10px;
  justify-content: center;
  padding: 12px;
  border: 1px solid black;
  border-radius: 20px;
  text-decoration: none;
  width: 75px;
  color: white;
  background-color: black;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.header__button:hover {
  color: #0dc80b;
  background-color: black;

}

.header__name {
  font-size: 25px;
  font-weight: 500;
  padding: 5px 5px 2px 10px;
  text-decoration: none;
  color: black;
  background-color: white;
}

.header__logo {
  width: 40px;
  background-color: white;
  margin: none;
}

.page__wrapper {
  display: flex;
  flex-direction: column;
  background-color: white;
}


.mp_container{
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

.body__wrapper{
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;

}

.body__session1 {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  background-image: url('../images/background01.jpeg');
  background-size: cover;
  background-repeat: no-repeat;
  height: 750px;
  border-top: 1px solid black;
  background-color: white;

}
.session1_container{
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: transparent;
}

#body__h1 {
  color: greenyellow;
  font-size: 70px;
  font-weight: 400;
  padding: 30px;
  background-color: transparent;
}

#body__h2 {
  color: greenyellow;
  font-size: 24px;
  font-weight: 400;
  background-color: transparent;
}

.body__button {
  display: flex;
  margin-right: 10px;
  margin-left: 10px;
  justify-content: center;
  padding: 12px;
  border: 1px solid greenyellow;
  border-radius: 20px;
  text-decoration: none;
  width: 85px;
  color: black;
  font-size: 15px;
  font-weight: 600;
  background-color: greenyellow;
  margin: 20px;
}


.body__button:hover {
  color: white;
  background-color: black;
  border: 1px solid black;
}

#body__button_1 {
  color: white;
  background-color: black;
  border: 1px solid black;
}

#body__button_1:hover {
  background-color: rgb(87, 85, 85);
  border: 1px solid rgb(87, 85, 85);
}

#body__button_0:hover {
  background-color: rgb(241, 223, 25);
  border: 1px solid rgb(241, 223, 25);
  color: black;
}

#body__button_3 {
  color: white;
  background-color: black;
  border: 1px solid black;
  width: 150px;
  margin: 10px;
}

#body__button_3:hover {
  background-color: rgb(87, 85, 85);
  border: 1px solid rgb(87, 85, 85);
}

#body__session3 {
  background-image: url('../images/background03.png');
  background-color: transparent;
}

#body__h1-1 {
  color: black;
  font-size: 55px;
  font-weight: 400;
  background: transparent;
}

.navbar__right {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.navbar__left {
  display: flex;
  align-items: center;
  padding: 6px;
}

.navbar_link {
  display: flex;
  margin-right: 8px;
  justify-content: center;
  padding: 8px;
  border-radius: 20px;
  text-decoration: none;
  width: 100px;
  color: white;
  font-size: 14px;
  font-weight: 600;
}

.navbar_link:hover {
  color: #0dc80b;
}

.dataResult {
  width: 200px;

  background-color: lightgrey;
  /* box-shadow: white 0px 5px 15px; */
  border: white solid 1px;
  border-top: none;
  border-radius: 5px;
  overflow: hidden;
  overflow-y: auto;
}

.dataResult::-webkit-scrollbar {
  display: none;
  background-color: lightgrey;
}

.dataResult .dataItem {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  color: black;
  text-decoration: none;
}

.dataItem p {
  margin-left: 10px;
}


.dataItem:hover {
  background-color: lightgrey;
}

.dataResult:hover {
  background-color: lightgrey;
}

#blacknow {
  background-color: black;
}

.dataName {
  background-color: transparent;
}

#search_icon {
  margin: 5px;
}
.searchInputs{
  margin-right: 70px;
}
.search_wrapper {

}
.searchbox{
  border-radius: 4px;
  width: 400px;
  height: 34px;
  box-shadow: none;
}

.home_page {
  background-color: white;
  height: 500px;
}

#blacknow {
  height: 100px;
}

#logo_black {
  background-color: black;
  margin: 22px 15px 15px 15px;
}
.searchall{
  position: absolute;
  z-index: 1000;
  left: 800px;


}
