/* #single-watchlist-container {
    border: white solid 3px;
    width: 50%;
    margin: auto;
    margin-top: 80px;
    align-items: center;
}

#single-watchlist-title {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}


#single-watchlist-table {
    width: 100%;
    margin-top: 20px;
}

.table-row-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-content: space-between;
}

#each-single-stock {
    cursor: pointer;
}

.single-watchlist-table-name-column {
    width: 50%;
}

.table-symbol-price-columns {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#price-in-single-list {
    padding-right: 0;
}

th {
    padding: 30px 30px 30px 0;
}

td {
    padding: 15px 15px 15px 0;

}

#single-watched-stock-column {
    text-decoration: none;
}

#single-watched-stock-column:hover {
    font-weight: bold;
} */



#singleWatchlist-page-container {
    margin: auto;
    width: 60%;
    display: flex;
    flex-direction: column;
}

#singleWatchlist-title-ctn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 50px;
}

#singleWatchlist-table {
    /* border: white 2px solid; */
    margin-top: 50px;
}

#singleWatchlist-tr {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
}

.singleWatchlist-table-header {
    font-size: large;
    padding: 10px;
}

.singleWatchlist-table-row {
    padding: 10px;
}

.transaction_quantity {
    /* justify-content: center; */
    align-items: center;
}