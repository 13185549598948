body{
    display: flex;
    justify-content: center;
    align-items: center;

}

#root{
    width: 100vw;
    height: 100vh;

}
.justforwhite {
    background-color: white;
    height: 100vh;
    width: 100vw;
}

.justforwhite1 {
    background-color: white;
    height: 100vh;
    width: 100vw;


}

.bblackfwhite {
    color: black;
    background-color: white;
}

.blackblue {
    /* color: blue; */
    color: black;
    font-weight: bold;
    background-color: white;
}

.button_field {
    margin-top: 25px;
    display: grid;
    justify-content: left;
    background-color: white;
}

.button {

    display: flex;
    justify-content: center;
    padding: 12px;
    border-radius: 20px;
    color: rgb(255, 255, 255);
    font-weight: 600;
    background-color: rgb(0, 0, 0);
    margin-top: 20px;
}

.body__button{
cursor: pointer;
}

.error_message {
    color: red;
    background-color: white;
    padding-bottom: 10px;
}

.input_field {
    width: 90%;
    height: 6px;
    padding: 1em;
    margin-top: 10px;
    margin-bottom: 25px;
    border-radius: 5px;
    background-color: white;
    border-color: #f0f3f5;
}

#lg_inputField{
    width: fit-content;
}

.login_body {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    margin-left: 200px;
}

.login_context {
    display: flex;
    flex-direction: column;
    align-items: start;
    /* padding: 50px 50px 50px 50px; */
    background-color: white;
    margin-right: 400px;
}

.login_header {
    margin-bottom: 20px;
    background-color: white;
}

.login_form {
    background-color: white;
    /* margin-left: 70px; */
    /* margin-bottom: 300px; */
    /* margin-left: 200px; */

}

.login_wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    background-color: white;
}
.lg_seperator{

}
.signup_wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: white;

}

.no_account {
    margin-top: 65px;
    background-color: white;
}

#login_background {
    /* background-position: top; */
    height: 913px;
    width: 900px;
    background-image: url('../images/robinhold_login.jpg');
    background-repeat: no-repeat;
    background-size: 100%;
    background-color: white;
    /* position: fixed; */


}

#signup_background {
    /* background-position: top; */
    height: 100vh;
    width: 600px;
    background-image: url('../images/robinhold_signup.jpg');
    background-repeat: no-repeat;
    background-size: 100%;
    background-color: white;
    /* margin-right: 300px; */
    /* display: flex; */
    /* flex-direction: column;
        justify-content: center;
        align-items: center; */
    /* background-size: cover; */
    /* height: 550px;
        border-top: 1px solid black; */
}

.signup_body {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 50px 50px 50px 50px;
    background-color: white;
    min-height: 750px;
}

#auth_label {
    font-size: larger;
    font-weight: bold;
}

#auth_header{
    margin-top: 10px;
}

.login_session2 {
    background-color: white;
    height: 500px;
}
