* {
  color: white;
  background-color: black;
}

.body-wrapper {
  background-color: black;
  display: grid;
  z-index: -1;
  place-items: center;
}

.body-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: black;
  padding-top: 36px;
  width: 70%;
  /* box-shadow: -1px 4px 20px -6px rgba(0, 0, 0, 0.7); */
  color: white;
  /* z-index: 50; */

  position: relative;
  margin-left: 1%
}

.errorList {
  display: flex;
  list-style: none;
  color: #ff385c;
  font-size: 14px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #1e2124;
}



/* ------------LEFT SIDE---------------- */

.portfolio-wrapper {
  /* flex: 0.7; */
  display: flex;
  flex-direction: column;
  background-color: black;
  margin-right: 100px;
  width: 700px
}

.pf-left-container {
  /* flex: 1; */
  background-color: black;
  flex-basis: auto;
  font-size: 12px;

}

.pf-graph {
  height: 300px;
  width: 700px;
}

.portfolio_label {
  border-bottom: 1px groove gray;
  padding-top: 15px;
  padding-bottom: 5px;
}

.portfolio_news {
  padding-top: 15px;
  padding-bottom: 15px;
}

.buying-power-header {
  padding: 20px 0px 20px 0px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #3b4754;
  margin-bottom: 60px;
}

.daily_movers_label {
  border-top: 1px groove gray;
  padding-top: 15px;
}

.daily_movers_small {
  margin-top: 5px;
  margin-bottom: 10px;

}

.pf-chart-wrapper {
  width: 100%;
  max-height: 305px
    /* margin-right: 100px; */
}

#add-amount {
  border: solid 1px grey;
  width: 50px;
  border-radius: 5px;
  margin-bottom: 10px;
}

/* --------------------time period-------------------------- */
.timeperiod__container {
  display: flex;
  border-bottom: 1px solid #3b4754;
}


.timeperiod__buttons__container {
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-bottom: 0px;
}

.timeperiod__button {
  font-weight: bold;
  padding: 10px 10px 20px 10px;
  cursor: pointer;
}

.timeperiod__button:hover {
  color: #5AC53B;
}

/* .timeperiod__button.active {
  color: #5AC53B;
} */

.timeperiod__button.active {
  border-bottom: 2px solid #5AC53B;
}

/* ------------------buying power--------------------- */
.buying-power-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 100px;
  border-radius: 5px;
  /* border: white solid 1px; */
}

/* .bp_label {
  font-family: ;
} */

.buying-power-container {
  /* border: solid white 1px; */
  /* border-radius: 10px; */
}

.buying-power-wrapper:hover {
  cursor: pointer;
}

/* .add-funds-form-div{
  border: white solid 1px;
} */


.deposit-funds {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  font-size: 13.5px;

}

.pf-form-break {
  background-color: rgb(200, 200, 200);
  width: 100%;
  height: 1px;
  /* margin-left: -11px;
    margin-top: 10px; */
  margin-bottom: 10px;
}

.flex-between {
  margin-top: 17px;
  margin-bottom: 17px;
  background-color: rgb(30, 33, 36);
}

.addFundForm {
  margin-bottom: 10px;
  margin-top: 20px;
}

.Deposit-fund-button {
  cursor: pointer;
  width: 100%;
  background-color: #00C805;
  width: 100px;
  height: 35px;
  font-weight: bold;
  border-radius: 5px;
  border: none;
  margin-bottom: 5px;
}

.Deposit-fund-button:hover {
  background-color: rgb(12, 128, 30);
}

.add-funds-container {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  width: 200px;
  border-radius: 3px;
  background-color: #1e2124;
  border: 0.5px solid rgb(200, 200, 200, 0.5);
}

.grey-background-label {
  background-color: #1e2124;
  margin-top: 15px;
  /* text-align: center; */

}

.addFund-form {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 200px;
  margin: auto;
  background-color: #1e2124;
  align-items: center;
}

.deposit-message {
  margin-top: 10px;
}

/* ---------------------RIGHT SIDE----------------------- */
.watchlist-wrapper {
  flex: 0.3;
  display: flex;
  flex-direction: column;
  height: fit-content;
}

.pf-graph {
  height: 300px;
}

.team-container{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.molly-pic{
  width: 300px;

}
.team-header{
  margin-bottom: 50px;
  margin-top: 40px;
}

.header-img-container{
  display: flex;
  flex-direction: row;
}


.stockLine{
  margin-top: 75px;
}


.team-member-container{
  margin-top: 20px;
  margin-bottom: 300px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.member-box{
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  /* border: solid green 1px; */
  width: 300px;
  /* height: 200px; */
  align-items: center;
}

.house-crest{
  width: 190px;
  height: 160px;
}

.info-icon{
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.team-member-info{
  display: flex;
  flex-direction: column;
  /* margin-top: 20px; */
}

.tm-name{
  margin-top: 20px;
  text-align: center;
}

.social-icon{
  width: 30px;
  margin-right: 20px;
  margin-left: 20px;
}

.social-icon-li{
  width: 30px;
  margin-right: 20px;
  margin-left: 20px;
}

/* .portfolio-chart-container {
  display: flex;
  flex-direction: column;
} */

/* .port_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 30px;
}

.port_left {
  display: flex;
  flex-direction: column;
}

.LineGraph {
  height: 200px;
}

.page-body-Wrapper{
  background-color: black;
  margin: 0;
  display: grid;
  place-items: center;
}



.portfolio-wrapper {
  flex: 0.7;
  color: white;
}

.chart-header {
  text-align: left;
}

.chart-header h1 {
  margin: 0;
}

.chart-header p {
  margin-top: 0;
}

.pf-chart-wrapper {
  width: 100%;
}





.timeperiod__container {
  display: flex;
  border-bottom: 1px solid #3b4754;
}

.timeperiod__buttons__container {
  display: flex;
  align-items: center;
}

.timeperiod__button {
  font-weight: bold;
  padding: 10px 10px 20px 10px;
  margin-left : 48px;
  cursor: pointer;
}

.timeperiod__button:hover {
  color: #5ac53b;
  transform: scale(1.05);
}

.timeperiod__button.active {
  color: #5ac53b;
  border-bottom: 2px solid #5ac53b;
} */
